import React from 'react';
import './downloadapp.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const DownloadApp = () => {
	const isSmallScreen = useIsSmallScreen(992);
	return (
		<>
			<section className="downloadapp-wraper">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 col-md-10 col-11 layout-set downloadapp-layout">
							<div className="row">
								<div className="col-lg-7 col-md-12 col-12 px-lg-5 px-md-5 px-3">
									<div className="content-spacing">
										<h2 className="dapp-title lora">HoGrowth App (Coming Soon)</h2>
										<p className="dapp-subtitle g_reg py-3">
											Coming to your favorite devices. Learn Today and Earn Profits
										</p>
										<div className="d-flex w-100 align-items-center justify-content-start flex-column flex-sm-row gap-sm-2 gap-4">
											<a rel="noopener noreferrer" style={{ cursor: 'default' }}>
												<img
													src={toAbsoluteUrl('/media/downloadapp-images/playstore.png')}
													alt="PlayStore_Image"
												/>
											</a>
											<a rel="noopener noreferrer" style={{ cursor: 'default' }}>
												<img
													src={toAbsoluteUrl('/media/downloadapp-images/apple.png')}
													alt="Apple_Image"
												/>
											</a>
										</div>
									</div>
								</div>

								<div className="col-lg-5 col-md-12 col-12 mb-lg-0 mb-md-4 d-flex align-items-center justify-content-center mb-4">
									<img
										src={toAbsoluteUrl(
											`/media/downloadapp-images/${
												isSmallScreen ? 'hogrowth-small-screen' : 'hoGrowth-mobile-image'
											}.png`,
										)}
										alt="Dash1"
										style={{ width: 'auto' }}
										height={450}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DownloadApp;
