import React from 'react';
import './coursecta.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { COURSE_URL } from 'pages/routing/routePages';

const CourseCta = () => {
  const navigate = useNavigate();
  const handleallcourse = () => {
    navigate(COURSE_URL);
  };
  return (
    <section className="cta-wrapper">
      <div className="container">
        <div className="row cta-layout">
          <div className="col-lg-10 col-md-10 col-10 layout-set d-flex">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12 ">
                <div className="mt-4">
                  <h2 className="cta-title lora">
                    Transform Your Career with Skills Learned at <br />
                    <span className="color2">HoGrowth Academy</span>
                  </h2>
                  <p className="cta-subtitle g_reg my-4">
                    Empower your career with skills from HoGrowth Academy. Propel
                    your journey forward with our transformative education.
                    Elevate your future today.
                  </p>
                  <div className='text-center text-lg-start'>
                    {/* <button className="button button-yellow g_semi">
                      Explore All Courses 
                    </button> */}
                    <button
                      className="button explore-btn explore-btn-hover g_mid"
                      onClick={handleallcourse}
                    >
                      Explore All Courses
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="mt-lg-0 mt-5">
                  <img
                    className="img-fluid"
                    src={toAbsoluteUrl("/media/cta-images/cta.png")}
                    alt="CTA"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseCta;