import React from "react";

interface TopTitleSectionProps {
  title: string;
  description: string;
}

const TopTitleSection: React.FC<TopTitleSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="info-wrapper col-lg-10 col-10 mx-auto">
          <h2 className="heading-title mb-4 lora">{title}</h2>
          <p className="privacy-desc g_reg">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default TopTitleSection;
