import React, { useEffect, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { getUserByToken } from 'pages/auth/core/_requests';
import './PersonalDetail.css';
import { UsersGendersEnum } from 'components/_v2/Course/type';

const UPDATE_IMAGE = gql`
	mutation UpdateImage($imageUploadInput: ImageUploadInput!) {
		UpdateImage(imageUploadInput: $imageUploadInput)
	}
`;

const UPDATE_STUDENT_PROFILE = gql`
	mutation NewUpdateStudentProfile($input: NewUpdateStudentProfileInput!) {
		NewUpdateStudentProfile(NewupdateStudentProfileInput: $input)
	}
`;

const GET_COUNTRIES_WITH_CODE = gql`
	query GetCountriesWithCode {
		getCountriesWithCode {
			id
			countryName
			countryStdCode
			countryCode
		}
	}
`;

const SEND_MOBILE_OTP = gql`
	mutation sendOtp($mobileNo: String!) {
		sendOtp(mobileNo: $mobileNo)
	}
`;

const SEND_EMAIL_OTP = gql`
	mutation sendEmailOtp {
		sendEmailOtp
	}
`;

const VERIFY_EMAIL_OTP = gql`
	mutation verifyEmailOtp($otp: String!) {
		verifyEmailOtp(otp: $otp)
	}
`;

const VERIFY_OTP = gql`
	mutation verifyOtp($otp: String!) {
		verifyOtp(otp: $otp)
	}
`;

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const countryNames = ['India'];

interface FormValues {
	fullname: string;
	birthdate: Date;
	phoneNumber: string;
	email: string;
	country: string;
	state: string;
	city: string;
	gender: UsersGendersEnum;
	pincode: string | null;
	otpCode: number | null;
	genderDropdownOpen: boolean;
	countryDropdownOpen: boolean;
	stateDropdownOpen: boolean;
	cityDropdownOpen: boolean;
}

const PersonalDetail = () => {
	const { user, setUser, localStorageAuthToken } = useUserContext();
	const [openVerification, setOpenVerification] = React.useState(false);
	const [mobileNo, setMobileNo] = React.useState('');
	const [emailVerificationOpen, setEmailVerificationOpen] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [displayImage, setDisplayImage] = useState<string | null>(null);
	const [changesMade, setChangesMade] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [hasImageChanged, setHasImageChanged] = useState(false);

	const [otp, setOtp] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [modalEmailOpen, setModalEmailOpen] = useState(false);
	const [seconds, setSeconds] = useState(89);
	const [canResend, setCanResend] = useState(false);

	const filteredCountryNames = countryNames.filter((country) =>
		country.toLowerCase().startsWith(searchTerm.toLowerCase()),
	);

	const [updateImage] = useMutation(UPDATE_IMAGE);
	const [updateStudentProfile] = useMutation(UPDATE_STUDENT_PROFILE);
	const [sendMobileOtp] = useMutation(SEND_MOBILE_OTP);
	const [verifyOtp, { loading: verifyOtpLoading, error: verifyOtpError }] = useMutation(VERIFY_OTP);
	const [sendEmailOtp] = useMutation(SEND_EMAIL_OTP);
	const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP);
	const countryDropdownRef = useRef(null);
	const genderDropdownRef = useRef(null);

	const validationSchema = Yup.object({
		fullname: Yup.string()
			.required('Full Name is required')
			.matches(/^[A-Za-z\s]+$/, 'Only Character Allow'),
		birthdate: Yup.string().required('Birthdate is required'),
		phoneNumber: Yup.string()
			.required('Phone Number is required')
			.matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
		email: Yup.string().email('Invalid email address').required('Email is required'),
		country: Yup.string()
			.required('Country is required')
			.oneOf(countryNames, 'Country is required'),
		state: Yup.string()
			.required('State is required')
			.matches(/^[A-Za-z\s]+$/, 'Only Character Allow'),
		city: Yup.string()
			.required('City is required')
			.matches(/^[A-Za-z\s]+$/, 'Only Character Allow'),
		gender: Yup.string()
			.required('Gender is required')
			.oneOf(['MALE', 'FEMALE', 'Other'], 'Gender is required'),
	});

	useEffect(() => {
		if (user && user.userProfile && user.userProfile.length > 0) {
			const imageUrl = user.userProfile[0].imageUrl;
			setDisplayImage(imageUrl);
		}
	}, [user]);
	const formik: FormikProps<FormValues> = useFormik({
		initialValues: {
			fullname: user?.fullName,
			birthdate: user?.userProfile[0]?.dob || new Date(),
			phoneNumber: user?.userProfile[0]?.mobileNo || '',
			email: user?.email || '',
			country: user?.userProfile[0]?.country?.countryName || 'Select Country',
			state: user?.userAddress[0]?.state || '',
			city: user?.userAddress[0]?.city || '',
			gender: user?.userProfile[0]?.gender || UsersGendersEnum.OTHER,
			pincode: user?.userAddress[0]?.zipCode || null,
			otpCode: null,
			genderDropdownOpen: false,
			countryDropdownOpen: false,
			stateDropdownOpen: false,
			cityDropdownOpen: false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				await updateStudentProfile({
					variables: {
						input: {
							name: values.fullname,
							dob: values.birthdate,
							mobileNo: values.phoneNumber,
							email: values.email,
							countryId: values.country,
							state: values.state,
							city: values.city,
							gender: values.gender,
							pincode: values.pincode ?? '',
						},
					},
					onCompleted: async () => {
						toast.success('Profile updated successfully', {
							className: 'g_mid',
						});

						const { data } = await getUserByToken(localStorageAuthToken);

						if (data?.data?.getMe) {
							setUser(data.data.getMe);
						}
					},
				});
			} catch (error) {
				console.error('Error updating profile', error);
				toast.error(error?.message, {
					className: 'g_mid',
				});
			}
		},
	});

	const handleVerifyClick = async () => {
		try {
			const mobileNo = formik.values.phoneNumber;
			setMobileNo(mobileNo);
			await sendMobileOtp({
				variables: {
					mobileNo,
				},
			});
			toast.success('OTP sent successfully', {
				className: 'g_mid',
			});
			setModalOpen(true);
			setSeconds(89);
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP', {
				className: 'g_mid',
			});
		}
	};

	const handleEmailVerifyClick = async () => {
		try {
			const email = formik.values.email; // Get the current mobile number from the form
			setMobileNo(email); // Set the mobileNo state variable
			await sendEmailOtp();
			toast.success('OTP sent successfully', {
				className: 'g_mid',
			});
			setModalEmailOpen(true); // Open the modal
		} catch (error) {
			console.error('Error sending OTP', error);
			toast.error('Failed to send OTP', {
				className: 'g_mid',
			});
		}
	};
	const handleOtpVerification = async () => {
		try {
			await verifyEmailOtp({ variables: { otp } });
			toast.success('Email verified successfully', {
				className: 'g_mid',
			});
			setEmailVerificationOpen(false);
			setModalEmailOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			console.error('Error verifying OTP', error);
			toast.error('OTP does not match', {
				className: 'g_mid',
			});
		}
	};

	const handleVerifyOtp = async () => {
		try {
			await verifyOtp({ variables: { otp } });
			toast.success('Mobile Number verified successfully', {
				className: 'g_mid',
			});
			setModalOpen(false);
			const { data } = await getUserByToken(localStorageAuthToken);
			if (data?.data?.getMe) {
				setUser(data.data.getMe); // Update the user context with the new data
			}
		} catch (error) {
			console.error('Error verifying OTP', error);
			toast.error('OTP does not match', {
				className: 'g_mid',
			});
		}
	};

	const handleVerificationClose = () => {
		setModalOpen(false);
	};

	const handleEmailVerificationClose = () => {
		setModalEmailOpen(false);
	};

	const handleImageChange = (e: any) => {
		setHasImageChanged(true);
		const file = e.target.files[0];
		const reader: any = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setImage(reader.result);
			setDisplayImage(reader.result);
			setChangesMade(true);
		};
	};

	const handleImageUpload = async () => {
		if (!image) return;

		const imageData = {
			newImage: {
				name: 'profile-image.png',
				content: image.split(',')[1],
			},
		};

		try {
			await updateImage({
				variables: {
					imageUploadInput: imageData,
				},
				onCompleted: async () => {
					setDisplayImage(image);
					setImage(null);
					setChangesMade(false);
					setHasImageChanged(false);

					const { data } = await getUserByToken(localStorageAuthToken);
					if (data?.data?.getMe) {
						setUser(data.data.getMe);
					}

					toast.success('Image updated successfully', {
						className: 'g_mid',
					});
				},
			});
		} catch (error) {
			console.error('Error updating image', error);
			setHasImageChanged(false);
			toast.error('Failed to update image', {
				className: 'g_mid',
			});
		}
	};

	useEffect(() => {
		if (seconds > 0) {
			const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
			return () => clearTimeout(timer);
		} else {
			setCanResend(true);
		}
	}, [seconds]);

	const handleResendMobile = () => {
		setSeconds(89);
		setCanResend(false);
		handleVerifyClick();
	};
	const handleResendEmail = () => {
		setSeconds(89);
		setCanResend(false);
		handleEmailVerifyClick();
	};
	useEffect(() => {
		function handleClickOutside(event) {
			if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('countryDropdownOpen', false);
			}
			if (genderDropdownRef.current && !genderDropdownRef.current.contains(event.target)) {
				formik.setFieldValue('genderDropdownOpen', false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [formik]);

	return (
		<div className="row mt-5">
			<form onSubmit={formik.handleSubmit}>
				<div className="d-flex align-items-center dp-image-layout">
					<div>
						<img
							src={displayImage || toAbsoluteUrl('/media/header/static-user.svg')}
							alt="Profile_Image"
							style={{ width: '100px', height: '100px', borderRadius: '50%' }}
						/>
					</div>
					<div>
						<input
							type="file"
							accept="image/*"
							onChange={handleImageChange}
							style={{ display: 'none' }}
							id="imageUpload"
						/>
						<label htmlFor="imageUpload" className="btn upload-btn g_reg ms-md-4 ms-2">
							Upload New
						</label>
					</div>
					<button
						type="button"
						className={`upload-btn g_reg ms-2 border-0 ${!changesMade ? 'disabled-btn' : ''}`}
						disabled={!hasImageChanged}
						onClick={handleImageUpload}>
						Save Image
					</button>
				</div>
				<div className="row gy-lg-4 mt-3">
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="fullname" className="profile-labels g_mid">
							Full Name
						</label>
						<span className="text-danger">*</span>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="fullname"
							pattern="[A-Za-z\s]+"
							title="Only Character Allow"
							placeholder="Enter Full Name"
							{...formik.getFieldProps('fullname')}
						/>
						{formik.touched.fullname && formik.errors.fullname ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.fullname)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2" ref={genderDropdownRef}>
						<label htmlFor="gender" className="profile-labels g_mid">
							Gender
						</label>
						<span className="text-danger">*</span>
						<br />
						<div
							className="input-box-style form-control g_reg dropdown"
							style={{ cursor: 'pointer', position: 'relative' }}
							onClick={() =>
								formik.setFieldValue('genderDropdownOpen', !formik.values.genderDropdownOpen)
							}>
							{formik.values.gender || 'Select Gender'}
							{formik.values.genderDropdownOpen ? (
								<FaChevronUp
									style={{
										position: 'absolute',
										right: '10px',
										top: '50%',
										transform: 'translateY(-50%)',
										pointerEvents: 'none',
									}}
								/>
							) : (
								<FaChevronDown
									style={{
										position: 'absolute',
										right: '10px',
										top: '50%',
										transform: 'translateY(-50%)',
										pointerEvents: 'none',
									}}
								/>
							)}
						</div>
						{formik.values.genderDropdownOpen && (
							<ul className="dropdown-menu show" style={{ maxWidth: '380px', marginTop: '0.5rem' }}>
								<li
									className="dropdown-item m-2"
									onClick={async () => {
										await formik.setFieldValue('gender', 'MALE');
										await formik.setFieldTouched('gender', true);
										formik.setFieldValue('genderDropdownOpen', false);
										formik.validateField('gender'); // Trigger validation immediately after setting the value
									}}>
									Male
								</li>
								<li
									className="dropdown-item m-2"
									onClick={async () => {
										await formik.setFieldValue('gender', 'FEMALE');
										await formik.setFieldTouched('gender', true);
										formik.setFieldValue('genderDropdownOpen', false);
										formik.validateField('gender'); // Trigger validation immediately after setting the value
									}}>
									Female
								</li>
								<li
									className="dropdown-item m-2"
									onClick={async () => {
										await formik.setFieldValue('gender', 'Other');
										await formik.setFieldTouched('gender', true);
										formik.setFieldValue('genderDropdownOpen', false);
										formik.validateField('gender'); // Trigger validation immediately after setting the value
									}}>
									Other
								</li>
							</ul>
						)}
						{formik.touched.gender && formik.errors.gender ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.gender)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="birthdate" className="profile-labels g_mid">
							Birthdate
						</label>
						<span className="text-danger">*</span>
						<br />
						<DatePicker
							clearIcon={null}
							className="form-control g_reg show"
							onChange={(date) => {
								formik.setFieldValue('birthdate', date);
							}}
							value={formik.values.birthdate}
							dayPlaceholder="dd"
							monthPlaceholder="mm"
							yearPlaceholder="yyyy"
							format="dd-MM-yyyy"
							calendarIcon={
								<img src={toAbsoluteUrl('/media/svg/shapes/calendar.svg')} alt="Calendar" />
							}
						/>
						{formik.touched.birthdate && formik.errors.birthdate ? (
							<div className="text-danger fs-7 mt-1">{String(formik.errors.birthdate)}</div>
						) : null}
					</div>
					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="phoneNumber" className="profile-labels g_mid">
							Phone Number
							<span className="text-danger">*</span>
							{user?.isMobileNumberVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span
									className="v-text g_mid ms-2"
									onClick={handleVerifyClick}
									style={{ cursor: 'pointer' }}>
									Verify
								</span>
							)}
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="phoneNumber"
							pattern="[0-9]{10}"
							placeholder="Enter Phone Number"
							{...formik.getFieldProps('phoneNumber')}
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
							}}
						/>
						{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.phoneNumber}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="email" className="profile-labels g_mid">
							Email
							<span className="text-danger">*</span>
							{user?.isVerified ? (
								<span className="veri-text g_mid ms-2">
									<img src={toAbsoluteUrl('/media/logo/veri-new.png')} alt="" className="me-1" />
									Verified
								</span>
							) : (
								<span role="button" className="v-text g_mid ms-2" onClick={handleEmailVerifyClick}>
									Verify
								</span>
							)}
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="email"
							placeholder="Enter Email"
							{...formik.getFieldProps('email')}
						/>
						{formik.touched.email && formik.errors.email ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.email}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2" ref={countryDropdownRef}>
						<label htmlFor="country" className="profile-labels g_mid">
							Country
						</label>
						<span className="text-danger">*</span>
						<br />
						<div
							className="input-box-style form-control g_reg dropdown"
							style={{ cursor: 'pointer', position: 'relative' }}
							onClick={() =>
								formik.setFieldValue('countryDropdownOpen', !formik.values.countryDropdownOpen)
							}>
							{formik.values.country || 'Select Country'}
							{formik.values.countryDropdownOpen ? (
								<FaChevronUp
									style={{
										position: 'absolute',
										right: '10px',
										top: '50%',
										transform: 'translateY(-50%)',
										pointerEvents: 'none',
									}}
								/>
							) : (
								<FaChevronDown
									style={{
										position: 'absolute',
										right: '10px',
										top: '50%',
										transform: 'translateY(-50%)',
										pointerEvents: 'none',
									}}
								/>
							)}
						</div>
						{formik.values.countryDropdownOpen && (
							<ul
								className="dropdown-menu show"
								style={{
									width: '380px',
									marginTop: '0.5rem',
									maxHeight: '15rem',
									overflowY: 'auto',
								}}>
								<li className="dropdown-item p-2">
									<input
										type="text"
										placeholder="Search country"
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
										className="form-control"
									/>
								</li>
								{filteredCountryNames.map((country, index) => (
									<li
										key={index}
										className="dropdown-item m-2"
										onClick={async () => {
											await formik.setFieldValue('country', country);
											await formik.setFieldTouched('country', true); // Mark field as touched
											formik.setFieldValue('countryDropdownOpen', false);
											await formik.validateField('country'); // Trigger validation after setting value
											setSearchTerm(''); // Reset search term when a country is selected
										}}>
										{country}
									</li>
								))}
							</ul>
						)}
						{formik.touched.country && formik.errors.country && (
							<div className="text-danger fs-7 mt-1">{formik.errors.country}</div>
						)}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="state" className="profile-labels g_mid">
							State
						</label>
						<span className="text-danger">*</span>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="state"
							pattern="[A-Za-z\s]+"
							title="Only Character Allow"
							placeholder="Enter State"
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^A-Za-z\s]/g, '');
							}}
							{...formik.getFieldProps('state')}
						/>
						{formik.touched.state && formik.errors.state ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.state}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="city" className="profile-labels g_mid">
							City
						</label>
						<span className="text-danger">*</span>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="city"
							placeholder="Enter City"
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^A-Za-z\s]/g, '');
							}}
							{...formik.getFieldProps('city')}
						/>
						{formik.touched.city && formik.errors.city ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.city}</div>
						) : null}
					</div>

					<div className="col-12 col-md-6 col-lg-4 mt-md-4 mt-2">
						<label htmlFor="pincode" className="profile-labels g_mid">
							Pincode
						</label>
						<br />
						<input
							className="input-box-style form-control g_reg"
							type="text"
							name="pincode"
							placeholder="Enter Pincode"
							onInput={(e: React.FormEvent<HTMLInputElement>) => {
								const input = e.currentTarget;
								input.value = input.value.replace(/[^0-9]/g, '').slice(0, 6);
							}}
							{...formik.getFieldProps('pincode')}
						/>
						{formik.touched.pincode && formik.errors.pincode ? (
							<div className="text-danger fs-7 mt-1">{formik.errors.pincode}</div>
						) : null}
					</div>
				</div>

				<div className="row mt-4">
					<div className="col-12">
						<button
							type="submit"
							className={`save-changes-btn g_mid ${!formik.dirty ? 'disabled' : ''}`}
							disabled={!formik.dirty}
							// onClick={handleImageUpload}
						>
							Save Changes
						</button>
					</div>
				</div>
			</form>
			{modalOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to <br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">OTP</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otp"
											placeholder="Enter OTP"
											type="password"
											value={otp}
											onChange={(e) => setOtp(e.target.value)}
										/>
										<div className="veri-desc mt-2 mb-4 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendMobile}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
									</div>
									<div className="col-12 mb-4 text-center">
										<button
											className="btn g_mid me-3 new-btn-fg-cancel-layout"
											type="button"
											onClick={handleVerificationClose}
											disabled={formik.isSubmitting}>
											Cancel
										</button>
										<button
											className="btn bg-color2 g_mid new-btn-fg-layout"
											type="button"
											onClick={handleVerifyOtp}>
											Continue
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			{modalEmailOpen && (
				<div className="modal-backdrop">
					<div
						className="modal fade show"
						id="exampleModalToggle"
						aria-hidden="true"
						aria-labelledby="exampleModalToggleLabel"
						tabIndex={-1}
						style={{ display: 'block' }}>
						<div className="modal-dialog modal-dialog-centered mx-auto ">
							<div className="modal-content container">
								<div className="modal-header border-0">
									<div className="mx-auto text-center">
										<img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
										<h2 className="veri-title lora">Verification</h2>
										<p className="veri-label veri-otp-layout g_reg">
											Please enter the 6-digit code sent to <br />
											<span className="veri-label-strong">{mobileNo}</span>
										</p>
									</div>
								</div>

								<form>
									<div className="fv-row mb-10 px-4">
										<label className="label-style g_reg">OTP</label>
										<input
											className="form-control input-inner-style g_reg"
											name="otp"
											placeholder="Enter OTP"
											type="password"
											value={otp}
											onChange={(e) => setOtp(e.target.value)}
										/>
										<div className="veri-desc mt-2 mb-4 text-start">
											{canResend ? (
												<button className="btn resend-btn" onClick={handleResendEmail}>
													Resend OTP
												</button>
											) : (
												<>
													Please wait&nbsp;
													<span className="veri-desc-strong">{seconds}s</span>
													&nbsp;before requesting another code
												</>
											)}
										</div>
									</div>
									<div className="col-12 mb-4 text-center">
										<button
											className="btn g_mid me-3 new-btn-fg-cancel-layout"
											type="button"
											onClick={handleEmailVerificationClose}
											disabled={formik.isSubmitting}>
											Cancel
										</button>
										<button
											className="btn bg-color2 g_mid new-btn-fg-layout"
											type="button"
											onClick={handleOtpVerification}>
											Continue
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
			{/* <Modal show={openVerification} onHide={handleVerificationClose}>
        <Verification
          openVerification={openVerification}
          handleVerificationClose={handleVerificationClose}
          handleRegisterClose1={handleRegisterClose1}
          toggleRegisterOpen={toggleRegisterOpen}
          toggleLoginOpen={toggleLoginOpen}
          mobileNo={mobileNo}
        />
      </Modal> */}
		</div>
	);
};

export default PersonalDetail;
